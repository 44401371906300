import { UseQueryResult } from '@tanstack/react-query';
import { graphql } from 'generated/gql/account-manager';
import type { UserQuery } from 'generated/gql/account-manager/graphql';
import { useAmQuery } from 'graphql/useAmQuery';
import { useEffect } from 'react';
import { isCustomerType, isInterval } from 'types/Users';
import {
  type CarrotTokenUserData,
  type FullyLoadedUserProviderData,
  type LoggedInUserProviderData,
  type SetUserProviderData,
  type UserProviderValue,
  UserStatus,
} from './UserProvider.types';

const userQueryDocument = graphql(`
  query user($wordpressUserId: Int!) {
    user(wordpressUserId: $wordpressUserId) {
      id
      activeAccount {
        owner {
          name
        }
      }
      carrotAccountStatus
      name
      email
      createdAt
      wordpressUserId
      wordpressUsername
      intercomUserHash
      superAdmin
      vip
      primaryIndustry {
        id
        name
      }
      primaryIndustryFocus {
        id
        name
      }
      currentSubscription {
        status
        canceledAt
        pastDueDate
        pastDueCancelAtDate
        pendingCancellation
        discount {
          endDate
          startDate
          coupon {
            name
            duration
            percentOff
            amountOff
          }
        }
        items {
          quantity
          price {
            id
            active
            customerType
            interval
            wordpressLabel
            version
            product {
              starter
            }
          }
        }
      }
      abilities {
        action
        subject
        limitingIds
      }
      carrotCrmConfig {
        id
      }
    }
  }
`);

/**
 * Get data about the current user from account-manager and update the UserProvider value
 */
function useUserData(userProviderValue: UserProviderValue) {
  const wordpressUserId = getWordPressUserId(userProviderValue);
  const result = useAmQuery(
    userQueryDocument,
    wordpressUserId ? { wordpressUserId } : undefined,
    { enabled: !!wordpressUserId }
  );

  useEffect(() => {
    if (result.isPending || userProviderValue.status !== UserStatus.LoggedIn) {
      return;
    }

    const updateUserData = () =>
      result
        .refetch()
        .then((newResult) =>
          updateUserProviderAfterUserQuery(
            updateUserData,
            newResult,
            userProviderValue
          )
        );

    updateUserProviderAfterUserQuery(updateUserData, result, userProviderValue);
  }, [result, userProviderValue]);
}

/**
 * Transition the UserStatus from LoggedIn to UserQueryError or FullyLoaded and update the provider data
 */
function updateUserProviderAfterUserQuery(
  updateUserData: FullyLoadedUserProviderData['updateUserData'],
  { data, error }: UseQueryResult<UserQuery>,
  userProviderValue: LoggedInUserProviderData & {
    setUserProviderData: SetUserProviderData;
  }
) {
  const { setUserProviderData } = userProviderValue;
  if (error || !data?.user) {
    setUserProviderData({
      ...userProviderValue,
      status: UserStatus.UserQueryError,
    });
    return;
  }

  const userData = fullyLoadedUserData(userProviderValue.userData, data.user);

  setUserProviderData({
    ...userProviderValue,
    status: UserStatus.FullyLoaded,
    userData,
    updateUserData,
  });
}

function fullyLoadedUserData(
  tokenData: CarrotTokenUserData,
  queryData: NonNullable<UserQuery['user']>
): FullyLoadedUserProviderData['userData'] {
  const { avatarUrl } = tokenData;
  const { id: accountManagerUserId, ...fullyLoadedUserData } = queryData;

  const starter = queryData.currentSubscription?.items.find(
    (item) => item.price.product.starter
  );
  const customerType = starter?.price.customerType;
  const interval = starter?.price.interval;

  return {
    ...fullyLoadedUserData,
    accountManagerUserId,
    avatarUrl,
    packagingVersion: starter?.price.version,
    customerType: isCustomerType(customerType) ? customerType : null,
    interval: isInterval(interval) ? interval : null,
    createdAt: new Date(fullyLoadedUserData.createdAt),
  };
}

function getWordPressUserId({ status, userData }: UserProviderValue) {
  switch (status) {
    case UserStatus.LoggedIn: {
      return parseInt(userData.id);
    }

    case UserStatus.FullyLoaded: {
      return userData.wordpressUserId;
    }

    default: {
      return null;
    }
  }
}

export { fullyLoadedUserData, useUserData };
